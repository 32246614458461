/* -Flexible image slide with functionality to add multiple images and choose
image layout.
---------------------------------------------------------------------------- */

// Slide with full screen image and overlay text
.block-image-and-text {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.image-bg {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.block-image-and-text-content {
  margin: 4% 0;
  padding: 0 4%;
  position: relative;
  width: 100%;
  z-index: 2;
  font-family: $font-stack-main;
  .text,
  .title {
    margin: 0;
  }
  .title {
    margin-bottom: 15px;
    line-height: 1.3;
    font-size: $h1-horisontal-slide;

    &.title--large {
      font-size: $h1-horisontal-slide-large;
    }
  }
  .text {
    font-size: $horisontal-slide-text-size;

    &.text--large {
      font-size: $horisontal-slide-text-size-large;
    }
  }
}

.block-image-and-text-content--dark {
  color: #000;
}
.block-image-and-text-content--light {
  color: #fff;
}

.block-image-and-text--center {
  justify-content: center;
  padding: 10%;

  .block-image-and-text-content {
    text-align: center;
  }
}
.block-image-and-text--top_left {
  .block-image-and-text-content {
    padding-right: 20%;
  }
}
.block-image-and-text--bottom_left {
  justify-content: flex-end;
  .block-image-and-text-content {
    padding-right: 20%;
  }
}
.block-image-and-text--top_right {
  .block-image-and-text-content {
    text-align: right;
    padding-left: 20%;
  }
}
.block-image-and-text--bottom_right {
  justify-content: flex-end;

  .block-image-and-text-content {
    text-align: right;
    padding-left: 20%;
  }
}

// Slide with one or multiple images
.image-slide {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  padding: 70px 95px 95px 95px;
}

.image-slide-text {
  margin-bottom: 50px;
  font-size: rem(40px);
  line-height: 1.2;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.image-slide-title {
  margin-bottom: 20px;
}

.image-slide-images {
  flex: 1 1 auto;
  min-height: 100px;
  height: 100%;
  text-align: center;

  img {
    max-height: 100%;
  }

  .image-slide--multiple-images & {
    display: flex;
    flex-wrap: wrap;

    align-content: flex-start;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.image-slide-images-count--2 {
  .image-block--portrait {
    flex-basis: calc(50% - 10px);
    max-height: 100%;

    &:first-child {
      margin-right: 20px;
    }
  }

  .image-block--landscape {
    flex-basis: 100%;
    max-height: calc(50% - 10px);

    &:first-child {
      margin-bottom: 20px;
    }
  }
  .image-block--portrait + .image-block--landscape {
    flex-basis: calc(50% - 10px);
    max-height: 100%;

    &:first-child {
      margin-bottom: 0;
    }
  }
  .image-block--landscape + .image-block--portrait {
    flex-basis: 100%;
    max-height: calc(50% - 10px);

    &:first-child {
      margin-bottom: 20px;
    }
  }
}

.image-slide-images-count--3 {
  .image-block {
    flex-basis: calc(50% - 10px);
    height: calc(50% - 10px);
  }

  .image-block:last-child {
    flex-basis: 100% !important;
  }

  .image-block:first-child {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.image-slide-images-count--4 {
  .image-block {
    flex-basis: calc(50% - 10px);
    max-height: calc(50% - 10px);
  }

  .image-block:first-child {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .image-block:nth-child(2) {
    margin-bottom: 20px;
  }
  .image-block:last-child {
    margin-left: 20px;
  }
}
