/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
.site-header {
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;
  background-color: $color-main;
}

.site-header-text {
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  flex-grow: 1;
  margin-left: 2%;
  color: #fff;

  p {
    margin: 0;
    font-size: 30px;
  }
}

.site-header-text--display {
  opacity: 1;
}

.header-data-date {
  padding-left: 5px;
}

.site-header-logo {
  margin-right: 2%;
  img {
    width: 55px;
    height: 55px;
  }
}
