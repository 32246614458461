/* -Slide with popular search terms
---------------------------------------------------------------------------- */
.popular-search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5% 10% 5% 10%;
  text-align: center;
  background-image: url('../../static/img/jula-signage_background-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-family: $font-stack-main;

  h1 {
    font-size: 72px;
    font-family: $font-stack-alt;
  }
  .text {
    font-size: 30px;
  }
  p {
    margin-bottom: 0;
  }
}

.popular-search-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $font-stack-alt;
  color: $color-main-text;
}

.search-item--0 {
  font-size: 130px;
  line-height: 140px;
}
.search-item--1 {
  font-size: 120px;
  line-height: 140px;
}
.search-item--2 {
  font-size: 110px;
  line-height: 140px;
}
.search-item--3 {
  font-size: 100px;
  line-height: 140px;
}
.search-item--4 {
  font-size: 88px;
  line-height: 140px;
}
.search-item--5 {
  font-size: 80px;
  line-height: 140px;
}
.search-item--6 {
  font-size: 70px;
  line-height: 140px;
}
.search-item--7 {
  font-size: 50px;
  line-height: 140px;
}
