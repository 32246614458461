.new-assortment-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: url('../../static/img/jula-signage_background-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-family: $font-stack-main;

  h1 {
    font-size: 72px;
    font-family: $font-stack-alt;
  }
  .text {
    font-size: 30px;
  }
  p {
    margin: 0;
  }
}

.new-assortment-header {
  padding: 5% 10% 0% 10%;
}

.new-assortment-slider {
  display: flex;
  padding-bottom: 5%;
  width: 100%;
  height: 100%;
  transition: transform 2s ease;
}

.product-slide {
  width: 50%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 2s ease, transform-origin 2s, opacity 2s ease;
}

.product-slide--current {
  transform-origin: center center;
  transform: scale(1);
  opacity: 1;
}

.product-slide--upcoming,
.product-slide--passed {
  transform: scale(0.7);
  transform-origin: 50% 50%;
  opacity: 0.2;

  .product-slide-product-brand-name,
  .product-slide-product-name,
  .product-slide-news-flag,
  .product-slide-static-text,
  .art-number {
    opacity: 0;
  }
}

.product-slide-image {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  img {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.product-slide-product-info {
  margin-top: 170px;

  .art-number {
    font-size: rem(42px);
    font-weight: normal;
  }
}
.product-slide-product-brand-name {
  font-size: rem(26px);
  padding-bottom: 25px;
}
.product-slide-product-name {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: rem(42px);
  font-weight: bold;
}
.product-slide-product-brand-name,
.product-slide-product-name,
.art-number {
  transition: opacity 1s ease;
  opacity: 1;
}

.product-slide-news-flag {
  width: 258px;
  height: 135px;
  margin-left: 80%;
  transition: opacity 1s ease;
  opacity: 1;
  margin-bottom: 30px;
}

.product-slide-static-text {
  transition: opacity 1s ease;
  color: $color-main;
  opacity: 1;
  font-size: 72px;

  .site-name {
    font-size: 105px;
  }
}
