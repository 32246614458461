/* -The global footer area
-----------------------------------------------------------------------------*/
.site-footer {
  display: flex;
  flex-direction: column;
  height: 175px;
  width: 100%;
}

div .site-footer-bottom-empty {
  background-color: $color-main;
}

.site-footer-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  background-color: $color-main;
  position: relative;

  div {
    position: absolute;
    left: 0px;
    top: 0px;
    color: #ee2d00c4;
  }

  img {
    width: 168px;
    height: 69px;
  }
}
.site-footer-bottom {
  position: relative;
  display: flex;
  height: 75px;
  background-color: $color-gray-dark;
  color: #fff;
  overflow: hidden;
}
.site-footer-content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  min-width: 300px;
  padding-left: 25px;
  background-image: linear-gradient(
    to left,
    rgba(51, 51, 51, 0),
    rgba(51, 51, 51, 0.9) 58%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.29;
}
.site-footer-title {
  font-weight: $font-weight-bold;
  margin: 0;
  font-size: rem(24px);
  .site-footer-sub-title {
    font-size: rem(14px);
  }
}

/* -Horizontal slider displaying budget for regions/countries
-----------------------------------------------------------------------------*/
.budget-container {
  display: flex;
  align-items: center;
  animation-name: marquee;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 3s;
  flex-shrink: 0;
}

.budget-item {
  margin-right: 20px;
  padding: 0 15px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 44px;
  border-radius: 22px;
  font-size: rem(24px);
  background-color: #4d4d4d;
  line-height: 24px;

  .budget-result {
    font-weight: $font-weight-bold;
    margin-right: 5px;
  }

  .budget-name {
    white-space: nowrap;
    margin-right: 15px;
  }

  .budget-country-flag {
    height: 24px;
    width: 24px;
    margin-right: 6px;
  }

  .budget-arrow-trend {
    height: 24px;
    width: 24px;

    &.budget-arrow-trend-right {
      transform: rotate(90deg);
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
