/* -Jula Net slide with article
---------------------------------------------------------------------------- */
.jula-net-container {
  position: relative;
  padding: 5% 10% 5% 10%;
  height: 100%;

  &.no-data {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
.jula-net-static-text {
  position: absolute;
  top: 30px;
  left: 55px;
  font-family: $font-stack-feltpen;
  font-size: rem(21px);

  .icon--arrow-angled {
    margin-top: 16px;
    margin-left: 10px;
  }
}
.jula-net-title {
  & > :last-child {
    font-family: 'Creighton Pro Book';
    font-weight: $font-weight-regular;
  }
}

.jula-net-publish-date {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: rem(30px);
  .title {
    font-weight: $font-weight-bold !important;
  }
}

.jula-net-image {
  margin-top: 30px;
  width: 890px;
  img {
    object-fit: contain;
    width: 100%;
    max-height: 700px;
  }
}

.jula-net-content {
  margin-top: 40px;
  font-size: rem(40px);
}
