/* -Wrapping classes
-----------------------------------------------------------------------------*/
// Wrappers have a page margin to prevent content from sitting snug
// against the browser window edge on smaller screens.

@mixin when-page-margin-small {
  @include media-max($breakpoint-page-margin-small) {
    @content;
  }
}
@mixin when-page-margin-large {
  @include media-min($breakpoint-page-margin-small + rem(1px)) {
    @content;
  }
}
@mixin page-margin {
  padding-left: $page-margin;
  padding-right: $page-margin;

  @include when-page-margin-small {
    padding-left: $page-margin-small;
    padding-right: $page-margin-small;
  }
}
@mixin page-margin-reset {
  padding-left: 0;
  padding-right: 0;
}

@mixin page-margin-top {
  margin-top: $page-margin-top;
}

@mixin edge-wrap-base {
  margin-left: auto;
  margin-right: auto;
}
@mixin wrap-base {
  @include edge-wrap-base;
  @include page-margin-top;
}

@mixin wrap-width {
  max-width: $wrap-width;
}
@mixin wrap-width-thin {
  max-width: $wrap-width-thin;
}
@mixin wrap-width-wide {
  max-width: $wrap-width-wide;
}
@mixin wrap-width-custom($size) {
  max-width: $size;
}

@mixin wrap {
  @include wrap-base;
  @include wrap-width;
}
@mixin wrap-thin {
  @include wrap-base;
  @include wrap-width-thin;
}
@mixin wrap-wide {
  @include wrap-base;
  @include wrap-width-wide;
}
@mixin wrap-custom($size) {
  @include wrap-base;
  @include wrap-width-custom($size);
}

@mixin edge-wrap {
  @include edge-wrap-base;
  max-width: $wrap-width;
}
@mixin edge-wrap-thin {
  @include edge-wrap-base;
  max-width: $wrap-width-thin;
}
@mixin edge-wrap-wide {
  @include edge-wrap-base;
  max-width: $wrap-width-wide;
}
@mixin edge-wrap-custom($size) {
  @include edge-wrap-base;
  max-width: $size;
}

.wrap {
  @include wrap;
}
.wrap-thin {
  @include wrap-thin;
}
.wrap-wide {
  @include wrap-wide;
}
.wrap-full {
  @include wrap-base;
}

.edge-wrap {
  @include edge-wrap;
}
.edge-wrap-thin {
  @include edge-wrap-thin;
}
.edge-wrap-wide {
  @include edge-wrap-wide;
}
