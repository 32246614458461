.viewport {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: auto;
  background-color: $color-viewport-background;

  .viewport-active & {
    width: 1080px;
    height: 1920px;
    box-shadow: 0 0 8px rgba(46, 46, 46, 1);
  }

  &.viewport--horizontal {
    width: 1920px;
    height: 1080px;
  }
}

.viewport-controls {
  position: fixed;
  bottom: 2em;
  left: 2em;
  width: 4.5em;
  height: 4.5em;
  border-radius: 50%;
  background: $color-gray-dark;
  color: #fff;
  z-index: $z-viewport-controls;
  box-shadow: 0 0 1px 0 #fff, 0 1px 10px 0 #fff;
}

.viewport-menu {
  display: none;
  position: fixed;
  left: rem(32px);
  bottom: rem(120px);
  width: 200px;
  list-style: none;
  color: #fff;
  z-index: $z-viewport-menu;

  li {
    background-color: $color-gray-dark;
    padding: 10px 0px 10px 15px;
    display: flex;
    align-items: center;

    input[type='radio'] {
      margin-top: -1px;
      vertical-align: middle;
      margin-right: 15px;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom: 0.5px solid #000;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .viewport-menu-open & {
    display: block;
  }
}
