/* -Custom fonts
-----------------------------------------------------------------------------*/
@font-face {
  font-family: 'Creighton Pro';
  src: url('../fonts/creighton-pro-bold.woff') format('woff');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Taz';
  src: url('../fonts/taz-light.woff') format('woff');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'Taz';
  src: url('../fonts/taz-regular.woff') format('woff');
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: 'Taz';
  src: url('../fonts/taz-bold.woff') format('woff');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'FeltpenCom';
  src: url('../fonts/FeltpenCom-Medium.ttf') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Creighton Pro Book';
  src: url('../fonts/creighton-pro-book.woff') format('woff');
  font-weight: $font-weight-regular;
  font-style: normal;
}
