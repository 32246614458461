// -------------------- Color definitions -------------------- //

$color-gray-dark: #333;
$color-blue: #4a90e2;
$color-red: #ca333a;
$color-light-gray: #f2f2f2;
$color-red-dark: #b73030;

// Lightest text color on white that meets WCAG AA accessibility requirement
$color-lightest-accessible-gray-on-white: #767676;

// -------------------- Semantic assignments -------------------- //
// When possible and logical, use colors through more meaningful
// "use case" names.

// Main body colors
$color-body-foreground: $color-gray-dark;
$color-body-background: #fff;

//Viewport colors
$color-viewport-background: $color-light-gray;
$color-viewport-foreground: $color-gray-dark;

// Disabled text
$color-disabled: $color-lightest-accessible-gray-on-white;

// Brand things like selection color
$color-main: $color-red;
$color-main-text: $color-red-dark;

// Links and main buttons
$color-action: $color-blue;
$color-action-foreground: #fff;

// Forms
$color-form-field-border: #ccc;

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
