/* -Icons
-----------------------------------------------------------------------------*/
/* AUTOGENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
}

$icon-arrow-angled-width: 34px;
$icon-arrow-angled-height: 24px;
.icon--arrow-angled {
  width: $icon-arrow-angled-width;
  height: $icon-arrow-angled-height;
}