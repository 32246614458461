// A combination of the classic reset and normalize.css

/* -Zeroing
-----------------------------------------------------------------------------*/
html,
body,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
img,
small,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
table,
caption,
th,
td,
figure {
  margin: 0;
  padding: 0;
  border: 0;
}

/* -Global
-----------------------------------------------------------------------------*/
html {
  overflow-y: scroll;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* -Element display
-----------------------------------------------------------------------------*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
[hidden],
template {
  display: none;
}
audio:not([controls]) {
  display: none;
  height: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid;
  margin: $text-spacing 0;
  padding: 0;
}

/* Make it work in IE */
wbr {
  display: inline-block;
}

/* -Embedded
-----------------------------------------------------------------------------*/
img,
object,
embed,
iframe,
video {
  max-width: 100%;
  width: auto;
  height: auto;
}
img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;

  /* For alt text */
  font-style: italic;
  color: #666;
}
svg:not(:root) {
  overflow: hidden;
}

/* -Form elements
-----------------------------------------------------------------------------*/
// Just resetting here, do actual styling in components/_forms.scss
label,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
textarea {
  overflow: auto;
}
input[type='checkbox'],
input[type='radio'] {
  padding: 0;
  cursor: pointer;
}
input[type='search'],
input[type='search']::-webkit-search-decoration,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
