/* -Grid image slide, a part of 'Flexible image slide'
---------------------------------------------------------------------------- */
.grid-slide {
  padding: 0 95px 95px 95px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.grid-slide-content {
  @include wrap-wide;

  margin-bottom: 20px;
  text-align: center;
}
.grid-slide-title {
  font-size: $h1-horisontal-slide;

  &.grid-slide-title--large {
    font-size: $h1-horisontal-slide-large;
  }
}
.grid-slide-text {
  font-size: $horisontal-slide-text-size;
  line-height: 1.2;

  &.grid-slide-text--large {
    font-size: $horisontal-slide-text-size-large;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.grid-images {
  @include grid-container;

  display: flex;
  flex: 1;
  min-height: 0;
  justify-content: center;
}

.grid-image-item {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
  }

  .grid-images-cols--1 & {
    @include grid-item(1);
  }
  .grid-images-cols--2 & {
    @include grid-item(1/2);
  }
  .grid-images-cols--3 & {
    @include grid-item(1/3);
  }
  .grid-images-cols--4 & {
    @include grid-item(1/4);
  }
}
