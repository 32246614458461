.error-page {
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.error-page-text {
  text-align: center;
  margin: 0;

  p {
    font-size: rem(30px);
  }
}

.site-footer-error {
  height: auto;
}
