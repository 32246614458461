$wrap-width: rem(890px);
$wrap-width-thin: rem(650px);
$wrap-width-wide: rem(1430px);

$page-margin: rem(50px);
$page-margin-small: $page-margin / 2;
$section-spacing: 70px;
$page-margin-top: 70px;

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 40px;
$form-control-font-size: 16px;
$form-control-radius: 4px;
$form-field-padding: em(5px, $form-control-font-size);
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size: 16px;
$button-height: em($form-control-base-height, $button-font-size);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

// Reduce text sizes
$breakpoint-type-medium: rem(1200px);
$breakpoint-type-small: rem(450px);

// Tighten layout
$breakpoint-grid-tight: rem(700px);
$breakpoint-page-margin-small: rem(550px);
