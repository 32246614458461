/* -Image with text
---------------------------------------------------------------------------- */
.block-image-and-text {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.image-bg {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.block-image-and-text-content {
  margin: 4% 0;
  padding: 0 4%;
  position: relative;
  width: 100%;
  z-index: 2;
  font-family: $font-stack-main;
  .text,
  .title {
    margin: 0;
  }
  .title {
    margin-bottom: 15px;
    line-height: 1.3;
    font-size: $h1-horisontal-slide;

    &.title--large {
      font-size: $h1-horisontal-slide-large;
    }
  }
  .text {
    font-size: $horisontal-slide-text-size;

    &.text--large {
      font-size: $horisontal-slide-text-size-large;
    }
  }
}

.block-image-and-text-content--dark {
  color: #000;
}
.block-image-and-text-content--light {
  color: #fff;
}

.block-image-and-text--center {
  justify-content: center;
  padding: 10%;

  .block-image-and-text-content {
    text-align: center;
  }
}
.block-image-and-text--top_left {
  .block-image-and-text-content {
    padding-right: 20%;
  }
}
.block-image-and-text--bottom_left {
  justify-content: flex-end;
  .block-image-and-text-content {
    padding-right: 20%;
  }
}
.block-image-and-text--top_right {
  .block-image-and-text-content {
    text-align: right;
    padding-left: 20%;
  }
}
.block-image-and-text--bottom_right {
  justify-content: flex-end;

  .block-image-and-text-content {
    text-align: right;
    padding-left: 20%;
  }
}
