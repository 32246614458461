.best-selling-container {
  @include wrap;

  text-align: center;

  p {
    margin: 0;
  }
}

.best-selling-text-content {
  margin-bottom: 60px;
  .text {
    font-size: 30px;
  }
}
.best-selling-arrow-text {
  display: flex;
  margin-right: 40px;
  margin-left: auto;
  max-width: 270px;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.24px;
  font-family: $font-stack-alt;

  .icon {
    flex-shrink: 0;
    align-self: flex-end;
    margin-left: 10px;
  }
}

.bar-row {
  margin-top: 38px;

  &:first-child {
    margin-top: 10px;
  }
}

.bar-description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  line-height: 1.1;

  .category {
    font-size: 32px;
  }
  .percentage {
    font-family: $font-stack-alt;
    font-size: 40px;
  }
}
.bar {
  height: 48px;
  width: 100%;
  border-radius: 44px;
  background-color: #fff;
}

.bar-inner {
  height: 100%;
  border: 4px solid #fff;
  border-radius: 32px;
}
