/* -Intranet slide with article
---------------------------------------------------------------------------- */
.intranet-container {
  @include section-padding;
  @include wrap-custom(rem(860px));

  position: relative;
  height: 100%;

  &.no-data {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
.intranet-static-text {
  position: absolute;
  top: 20px;
  left: -60px;
  font-family: $font-stack-feltpen;
  font-size: rem(21px);

  .icon--arrow-angled {
    margin-top: 16px;
    margin-left: 10px;
  }
}
.intranet-image {
  margin-top: 30px;

  img {
    object-fit: contain;
    width: 100%;
    max-height: 700px;
  }
}
.intranet-title {
  font-family: $font-stack-alt-second;
  font-weight: $font-weight-regular;
}
.intranet-publish-date {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: rem(30px);

  .title {
    font-weight: $font-weight-bold;
  }
}
.intranet-content {
  margin-top: 40px;
  font-size: rem(40px);
}
