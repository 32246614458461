/* -Form elements
-----------------------------------------------------------------------------*/
input[type='tel'],
input[type='url'],
input[type='text'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='password'],
textarea {
  height: $form-control-height;
  padding: 0 $form-field-padding;
  border: 1px solid $color-form-field-border;
  border-radius: $form-control-radius;
  font-size: rem($form-control-font-size);
  background-color: #fff;
  color: $color-body-foreground;
  transition: border-color 0.15s linear;

  &:focus {
    border-color: desaturate($color-action, 20%);
    outline: 0;
  }
}
textarea {
  height: auto;
  padding: $form-field-padding;
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 0.25em;
  vertical-align: middle;
}

form {
  input,
  textarea {
    display: block;
    width: 100%;
  }
  input[type='submit'],
  input[type='button'],
  input[type='reset'],
  input[type='radio'],
  input[type='checkbox'] {
    display: inline-block;
    width: auto;
  }
}

/* ------------------- Default styling ------------------- */
form[tabindex='-1']:focus {
  outline: 0 !important;
}

.form-field-group {
  @include grid-container;
}
.form-field {
  @include grid-item(1);
}
.form-field,
.form-footer {
  margin-top: 10px;
}
.form-required-legend {
  margin: 0 0 0.5em;
  font-size: 1rem;
}

// Honeypot field
.form-field--honeypot {
  width: 100% !important;
}
.form-field--honeypot--valid {
  display: none !important;
}

/* -------------------- Validation -------------------- */
.field-req {
  border: 0 !important;
  color: $color-error;
  font-weight: normal;
}

.form-error-small {
  margin-top: 1em;
}

// ----- Form messages -----
.form-errors,
.form-success {
  position: relative;
  margin-bottom: $text-spacing;
  padding: 1px 10px;
  font-size: rem(14px);
  border: 1px solid;
  border-left-width: em(16px * 3);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: em(-16px * 2);
    width: em(16px);
    height: em(16px);
    margin-top: em(-8px);
    background-size: em(16px) em(16px);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: 2;
  }
  p {
    margin: 0.5em 0;
  }
}
.form-errors {
  border-color: $color-error;
  color: $color-error-foreground;

  &::before {
    background-image: svg-url(
      '<svg width="16" height="16" viewBox="0 0 16 16"><path fill="#fff" d="M15.854 12.854L11 8l4.854-4.854a.503.503 0 0 0 0-.707L13.561.146a.499.499 0 0 0-.707 0L8 5 3.146.146a.5.5 0 0 0-.707 0L.146 2.439a.499.499 0 0 0 0 .707L5 8 .146 12.854a.5.5 0 0 0 0 .707l2.293 2.293a.499.499 0 0 0 .707 0L8 11l4.854 4.854a.5.5 0 0 0 .707 0l2.293-2.293a.499.499 0 0 0 0-.707z"/></svg>'
    );
  }
}
.form-success {
  border-color: $color-success;
  color: $color-success-foreground;

  &::before {
    background-image: svg-url(
      '<svg width="16" height="16" viewBox="0 0 16 16"><path fill="#fff" d="M13.5 2L6 9.5 2.5 6 0 8.5l6 6 10-10z"/></svg>'
    );
  }
}

.errorlist {
  list-style: none;
  margin: 0 0 1em;
}

// ----- Field messages -----
.field-error {
  display: block;
  margin-top: 2px;
  font-size: rem(14px);
  color: $color-error;

  svg {
    fill: currentColor;
    vertical-align: middle;
  }
}
.form-field--error,
.form-field--success {
  input,
  textarea {
    &:focus {
      padding-right: $form-field-padding;
      background: #fff !important;
      color: $color-body-foreground;
    }
  }
  input {
    padding-right: $form-field-padding + em(25px);
    background-repeat: no-repeat;
    background-size: em(24px) em(16px);
    background-position: 100% 50%;
  }
}
.form-field--error {
  input,
  textarea {
    border-color: $color-error;
    background-color: $color-error-background;
    color: darken($color-error, 20%);
  }
  input {
    background-image: svg-url(
      '<svg width="24" height="16" viewBox="0 0 24 16"><path fill="#{$color-error}" d="M8 1.4l6.7 13.4H1.3L8 1.4zM8 0c-.3 0-.7.2-1 .7L.2 14.3c-.5.9-.1 1.7 1 1.7h13.6c1.1 0 1.5-.8 1-1.7L9 .7C8.7.2 8.3 0 8 0z"/><path fill="#{$color-error}" d="M9 13c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zM8 11c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1s1 .4 1 1v3c0 .6-.4 1-1 1z"/></svg>'
    );
  }
}
.form-field--success {
  input,
  textarea {
    border-color: $color-success;
    background-color: $color-success-background;
    color: darken($color-success, 20%);
  }
  input {
    background-image: svg-url(
      '<svg width="20" height="12" viewBox="0 0 20 12"><path fill="#{$color-success}" d="M10.3 1.1l1.6 1.3c.1.1.1.2 0 .3l-6.9 8.2c0 .1-.1.1-.2.1s-.1 0-.1-.1l-4.6-3.8c-.1 0-.1-.2 0-.3l1.3-1.6c.1-.1.2-.1.3 0l2.8 2.3 5.5-6.4c.1-.1.2-.1.3 0z"/></svg>'
    );
  }
}
