// ----------------------------------------------------------------------------
// Simple grid mixins
// ----------------------------------------------------------------------------
// Each group of grid items must be wrapped with a grid-container, which has a
// negative margin equal to the grid gutter. This makes them infinitely
// nestable; just use a new wrap with child items.
//
// Create a grid with three columns.
//
//   .wrapper-element {
//     @include grid-container;
//   }
//   .child-elements {
//     @include grid-item(1 / 3);
//   }
//
// Create a grid with four columns and a smaller gutter. Values should
// probably always be the same for wrapper and items, do separate overriding
// rules if needed to avoid confusion.
// If not setting gutter values, the global default will be used.
//
//   .wrapper-element {
//     @include grid-container(20px, 10px);
//   }
//   .child-elements {
//     @include grid-item(1 / 4, 20px, 10px);
//   }
//
// Changing columns should be done with the specific mixin rather than the
// grid-item() mixin, to avoid unnecessary duplication.
//
//   .child-elements {
//     @include grid-item(1 / 2);
//
//     @include some-media-breakpoint {
//       @include grid-item-size(1 / 3);
//     }
//   }

$grid-gutter: 30px;
$grid-gutter-tight: $grid-gutter / 2;

// -------------------- Helpers -------------------- //
// Media query mixins for when the grid gutters are or aren't tight.
//
//   .selector {
//     margin-top: 20px;
//
//     @include when-grid-tight {
//       margin-top: 10px;
//     }
//   }

@mixin when-grid-tight {
  @include media-max($breakpoint-grid-tight) {
    @content;
  }
}

@mixin when-grid-wide {
  @include media-min($breakpoint-grid-tight + rem(1px)) {
    @content;
  }
}

// -------------------- Container -------------------- //
@mixin grid-container-gutter-size($size) {
  margin-left: -$size;
}

@mixin grid-container-single-row(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-container-gutter-size($size);

  display: flex;

  @include when-grid-tight {
    @include grid-container-gutter-size($size-tight);
  }
}
@mixin grid-container($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-container-single-row($size, $size-tight);

  flex-wrap: wrap;
}

// -------------------- Item -------------------- //
@mixin grid-item-size($size, $important: null) {
  @if type-of($size) == 'number' and $size <= 1 {
    flex: 0 0 $size * 100% $important;
    max-width: $size * 100% $important;
  } @else {
    flex: 0 0 $size $important;
    max-width: $size $important;
  }
}

@mixin grid-item-auto-size($basis: auto, $grow: 1, $shrink: 1) {
  flex: $grow $shrink $basis;
  max-width: none;
}

@mixin grid-item-gutter-size($size) {
  padding-left: $size;
}

@mixin grid-item-gutter(
  $gutter-size: $grid-gutter,
  $gutter-size-tight: $grid-gutter-tight
) {
  @include grid-item-gutter-size($gutter-size);

  @include when-grid-tight {
    @include grid-item-gutter-size($gutter-size-tight);
  }
}

@mixin grid-item-base(
  $gutter-size: $grid-gutter,
  $gutter-size-tight: $grid-gutter-tight
) {
  @include grid-item-gutter($gutter-size, $gutter-size-tight);
}

@mixin grid-item(
  $item-size,
  $gutter-size: $grid-gutter,
  $gutter-size-tight: $grid-gutter-tight,
  $flex-basis: 0px
) {
  @include grid-item-base($gutter-size, $gutter-size-tight);
  @if $item-size == auto {
    flex: 1 1 $flex-basis;
    max-width: none;
  } @else if $item-size == grow {
    flex: 1 0 $flex-basis;
    max-width: none;
  } @else if $item-size == shrink {
    flex: 0 1 $flex-basis;
  } @else {
    @include grid-item-size($item-size);
  }
}
@mixin grid-item-dynamic(
  $item-size,
  $gutter-size: $grid-gutter,
  $gutter-size-tight: $grid-gutter-tight
) {
  @include grid-item($item-size, $gutter-size, $gutter-size-tight, auto);
}

// -------------------- Alignment -------------------- //
@mixin grid-align-start {
  align-items: flex-start;
}
@mixin grid-align-center {
  align-items: center;
}
@mixin grid-align-end {
  align-items: flex-end;
}

// -------------------- Margin and padding -------------------- //
// Margin or padding matching the grid gutter size.

@mixin grid-gutter-rule(
  $rule,
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  $rules: ();
  @if type-of($rule) == 'list' {
    $rules: join($rules, $rule);
  } @else {
    $rules: append($rules, $rule);
  }

  @each $rule in $rules {
    #{$rule}: $size;
  }
  @include when-grid-tight {
    @each $rule in $rules {
      #{$rule}: $size-tight;
    }
  }
}

@mixin grid-margin-top($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-top, $size, $size-tight);
}

@mixin grid-margin-right($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-right, $size, $size-tight);
}

@mixin grid-margin-bottom(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-gutter-rule(margin-bottom, $size, $size-tight);
}

@mixin grid-margin-left($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-left, $size, $size-tight);
}

// Negative margin matching the current grid gutter size. Useful for lists of
// items that should have bottom spacing while the last row should still be
// against the bottom edge of the container.
//
//   .wrapper-element {
//     @include grid-container;
//     @include grid-pull-bottom;
//   }
//   .child-elements {
//     @include grid-item(1 / 4);
//     @include grid-margin-bottom;
//   }
@mixin grid-pull-bottom($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-bottom, -$size, -$size-tight);
}

@mixin grid-padding-top($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(padding-top, $size, $size-tight);
}

@mixin grid-padding-right(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-gutter-rule(padding-right, $size, $size-tight);
}

@mixin grid-padding-bottom(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-gutter-rule(padding-bottom, $size, $size-tight);
}

@mixin grid-padding-left($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(padding-left, $size, $size-tight);
}

// -------------------- Reset -------------------- //
// Reset wraps and items.
//
//   .wrapper-element {
//     @include grid-container;
//   }
//   .child-elements {
//     @include grid-item(1 / 4);
//   }
//
//   @include some-media-breakpoint {
//     .wrapper-element {
//       @include grid-container-reset;
//     }
//     .child-elements {
//       @include grid-item-reset;
//     }
//   }

@mixin grid-container-reset {
  display: block;
  margin-left: 0;
}

@mixin grid-item-reset {
  display: block;
  width: auto;
  max-width: none;
  padding-left: 0;
}
