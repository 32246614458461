/* -Standard slide with best selling products
---------------------------------------------------------------------------- */
.most-sold-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 5% 10% 5% 10%;
  text-align: center;
  color: $color-gray-dark;

  h1 {
    font-size: 84px;
    font-family: $font-stack-alt;
  }
  p {
    margin: 0;
  }
  .text {
    font-size: 30px;
  }
}

/* if using most sold-slider
.most-sold-slider {
  margin-top: 10%;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}
*/

.most-sold-content {
  margin-top: 10%;
  transition: transform 3s ease;
  transform: translateX(120%);
  display: flex;
  width: 100%;
  height: 100%;
  font-family: $font-stack-main;

  /* if using slider for most sold,uncomment this:
  position: absolute;
  top: 0;
  left: 0;*/
}
.most-sold-content--current {
  transform: translateX(0%);
}

.most-sold-content--reset {
  transition: none;
}

.most-sold-content--next {
  transform: translateX(120%);
}

.most-sold-content--fade-in {
  transform: translateX(0);
}

.most-sold-content--fade-out {
  transform: translateX(-120%);
}

.first-row {
  flex-grow: 1;
  margin-right: 1%;
  text-align: left;
}
.second-row {
  flex-grow: 1;
  text-align: left;
}

.row-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-right: 20%;
  align-items: flex-end;
}
.row-title {
  font-size: 30px;
  color: #343434;
  font-weight: bold;
}

.text-and-arrow {
  display: flex;
  flex-direction: row;
  position: relative;
  img {
    position: absolute;
    right: -30%;
    bottom: -30%;
  }
}

.row-text {
  font-size: 21px;
  font-family: $font-stack-feltpen;
}

.product-list {
  position: relative;
  padding: 15px;
  counter-reset: product-card;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(133, 106, 106, 0.16);
}
.product-card {
  display: flex;
  align-items: center;
  height: 94px;
  color: #000;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.first-row {
  .product-card::before {
    counter-increment: product-card;
    content: counter(product-card);
    position: absolute;
    left: -53px;
    min-width: 40px;
    text-align: right;
    font-size: 30px;
    font-weight: bold;
    color: #343434;
  }
}

.product-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 94px;
  margin-right: 3%;
  background-color: #f8f8f8;
  padding: 5px;
  img {
    max-height: 100%;
  }
}
.product-info {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.product-title {
  font-size: 22px;
  font-family: $font-stack-alt;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.product-art-number {
  font-size: 17px;
}

.product-rating {
  display: flex;
  height: 20px;
  align-items: center;
  p {
    font-size: 15px;
    margin: 0;
  }
}
.product-stars {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSI0ODBweCIgaGVpZ2h0PSI4OHB4IiB2aWV3Qm94PSIwIDAgNDc5IDg3IiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQgIWltcG9ydGFudDsiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3ZnIHg9IjAiPjxwYXRoIGlkPSJzdGFyIiBmaWxsPSIjYzNjM2MzIiBkPSJNNDUuNSwwTDU2LDM0IDkxLDM0IDYzLDU0IDczLDg3IDQ1LjUsNjcgMTgsODcgMjgsNTQgMCwzNCAzNSwzNFpNNDUuNSwyMyA1Miw0MSA2OCw0MSA1NSw1MSA2MSw3MCA0NS41LDU4IDMwLDcwIDM2LDUxIDIyLDQxIDQwLDQxWiIvPjwvc3ZnPjxzdmcgeD0iOTYiPjx1c2UgeGxpbms6aHJlZj0iI3N0YXIiIC8+PC9zdmc+PHN2ZyB4PSIxOTIiPjx1c2UgeGxpbms6aHJlZj0iI3N0YXIiIC8+PC9zdmc+PHN2ZyB4PSIyODgiPjx1c2UgeGxpbms6aHJlZj0iI3N0YXIiIC8+PC9zdmc+PHN2ZyB4PSIzODQiPjx1c2UgeGxpbms6aHJlZj0iI3N0YXIiIC8+PC9zdmc+PC9zdmc+') !important;
  background-repeat: no-repeat !important;
  position: relative !important;
  line-height: 1.3 !important;
  height: 16px !important;
  background-size: 90px 16px !important;
  width: 90px;

  span {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSI0ODBweCIgaGVpZ2h0PSI4OHB4IiB2aWV3Qm94PSIwIDAgNDc5IDg3IiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQgIWltcG9ydGFudDsiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3ZnIHg9IjAiPjxwYXRoIGlkPSJzdGFyIiBmaWxsPSIjZmZiZDAwIiBkPSJNNDUuNSwwTDU2LDM0IDkxLDM0IDYzLDU0IDczLDg3IDQ1LjUsNjcgMTgsODcgMjgsNTQgMCwzNCAzNSwzNFoiLz48L3N2Zz48c3ZnIHg9Ijk2Ij48dXNlIHhsaW5rOmhyZWY9IiNzdGFyIiAvPjwvc3ZnPjxzdmcgeD0iMTkyIj48dXNlIHhsaW5rOmhyZWY9IiNzdGFyIiAvPjwvc3ZnPjxzdmcgeD0iMjg4Ij48dXNlIHhsaW5rOmhyZWY9IiNzdGFyIiAvPjwvc3ZnPjxzdmcgeD0iMzg0Ij48dXNlIHhsaW5rOmhyZWY9IiNzdGFyIiAvPjwvc3ZnPjwvc3ZnPg==') !important;
    background-repeat: no-repeat !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    height: 16px !important;
    background-size: 90px 16px !important;
  }
}

.product-value {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: $font-stack-alt;
  font-weight: 900;
  font-size: 32px;
  font-style: bold;
}
