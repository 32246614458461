.main {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.block-video,
.block-image {
  flex-grow: 1;
  height: 100%;
}

.slider {
  position: relative;
  flex-grow: 1;
  height: 100%;
}

.slide {
  flex-grow: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 2s ease;
  transform: translateX(100%);
  font-size: 14px;
}

.slide--fade-in {
  transform: translateX(0);
}

.slide--fade-out {
  transform: translateX(-100%);
}

.slide--reset {
  transition: none;
}

.slider-page-dots {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 2%;
  display: flex;
  justify-content: center;
  transition: opacity 0.5s ease;
  opacity: 1;

  .slider[data-current-slide-type='imageSlide'] &,
  .slider[data-current-slide-type='videoSlide'] & {
    opacity: 0;
  }
}


.page-dot {
  border: 3px solid $color-gray-dark;
  width: 25px;
  height: 25px;
  border: solid 3px $color-gray-dark;
  border-radius: 50%;
  margin-left: 2%;
}
.page-dot {
  &.current {
    background-color: $color-gray-dark;
  }
}
