.lunch-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5% 10%;
  color: $color-gray-dark;
  text-align: center;

  .text {
    font-size: 30px;
  }
}
.block-content {
  height: 100%;
  width: 100%;
  font-size: 28px;

  .rich-text {
    width: 100%;
  }
}
